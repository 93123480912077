<template>
  <ul class="user-role-checkbox-puls-wrap">
    <!-- UPDATE 全选 -->
    <li class="el-checkbox-option all-option">
      <el-input v-model="queryStr" size="mini" @input="handleQuerySource"></el-input>
      <!-- @change="handleAllExist" -->
      <el-checkbox v-model="isAll" v-if="this.multipleMode"> 全选 </el-checkbox>
      <!-- @click="handleClearAllExist" -->
      <!-- <span v-if="hiddenClearOption" @click="isAll = false">清空</span> -->
    </li>
    <li class="el-checkbox-option" v-for="option in bufferSource" :key="option[optionAttr.valueKey]">
      <el-checkbox v-model="option.isExist" size="mini" :disabled="isBan" border @change="handleCheckOption(option)">
        {{ option[optionAttr.labelKey] }}
      </el-checkbox>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: [],
    },
    optionAttr: {
      type: Object,
      default() {
        return {
          labelKey: '',
          valueKey: '',
        };
      },
    },

    isBan: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: '',
    },
    border: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Object,
      default() {
        return {};
      },
    },
    role: {
      type: String,
      default: '',
    },
  },
  computed: {
    multipleMode() {
      console.log(this.multiple, this.role);
      return this.multiple[this.role];
    },
    bufferSource: {
      get() {
        const QUERYSTRING = ['', ...this.queryStr, ''].join('.*');
        const reg = new RegExp(QUERYSTRING);
        return this.queryStr === ''
          ? this.options
          : this.options.reduce((pre, cur) => {
              console.log(reg.test(cur[this.optionAttr.labelKey]));
              if (reg.test(cur[this.optionAttr.labelKey])) {
                pre.push(cur);
              }
              return pre;
            }, []);
      },
    },
    hiddenClearOption() {
      return this.isAll; //&&
    },
  },

  data() {
    return {
      isAll: false,
      queryStr: '',
      // bufferSource: [],
    };
  },

  watch: {
    isAll(newState, oldState) {
      this.updateSourceExistState();
      const exist = this.filterExistOption();
      this.feedbackSelected(exist);
    },
  },
  created() {
    console.log(this.multipleMode);
    // this.bufferSource = JSON.parse(JSON.stringify(this.options));
  },
  methods: {
    //搜索
    handleQuerySource() {},
    //选中所有元素
    handleAllExist() {},
    //取消选中所有元素
    handleClearAllExist() {},

    //选中元素
    handleCheckOption(option) {
      this.multipleChoice(option);
      const exist = this.filterExistOption();
      this.feedbackSelected(exist);
    },

    multipleChoice(option) {
      console.log(this.multipleMode);
      this.multipleMode || this.updateTagSourceExistState(option);
    },

    //更新目标元素isExist
    updateTagSourceExistState(tag) {
      const tmp = JSON.parse(JSON.stringify(tag));
      this.bufferSource.forEach((option) => {
        option.isExist = false;
        if (option[this.optionAttr.valueKey] === tag[this.optionAttr.valueKey]) {
          option.isExist = tmp.isExist;
        }
      });
    },

    //修改数据源状态
    updateSourceExistState() {
      this.bufferSource.forEach((option) => (option.isExist = this.isAll));
    },

    //查找被选中的选项
    filterExistOption() {
      return this.bufferSource.reduce((pre, cur) => {
        cur.isExist && pre.push(cur[this.optionAttr.valueKey]);
        return pre;
      }, []);
    },

    //返回数据
    feedbackSelected(arr) {
      this.$emit('get:selected', { type: this.type, arr });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-role-checkbox-puls-wrap {
  padding: 0;
  margin: 0;
  width: 100%;
  .el-checkbox-option {
    width: 100%;
    padding: 10px;
    &.all-option {
      display: flex;
      flex-direction: row;
      // padding: 10px 0;
      justify-content: space-between;
      align-items: center;
      .el-input {
        width: 200px;
      }
      .el-checkbox {
        width: auto;
      }
      .el-checkbox + span {
        color: var(--color-primary);
      }
    }
    /deep/ .el-checkbox {
      width: 100%;
      box-sizing: border-box;
    }
  }
}
</style>

<template>
  <el-card class="administrator-user-page page-container">
    <el-form slot="header" class="search-user-bar" size="small" :model="userInformation" :inline="true">
      <el-form-item label="用户名">
        <el-input v-model="userInformation.name" clearable @clear="handleClear">
          <el-button slot="append" icon="el-icon-search" @click="handleSearch(userInformation.name)"> 查询</el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <el-table :data="userList" :height="tHeight">
      <el-table-column label="序号" type="index" align="center" width="60"></el-table-column>
      <el-table-column prop="userId" label="编号" align="center"></el-table-column>
      <el-table-column prop="userAvater" align="center" label="头像" width="80" v-if="false">
        <template slot-scope="scope">
          <el-avatar shape="square" size="medium" :src="scope.row.userAvater"></el-avatar>
        </template>
      </el-table-column>
      <el-table-column prop="userName" label="用户名" align="center"></el-table-column>

      <el-table-column label="姓名" width="80" align="center">
        <template slot-scope="scope">
          <el-link type="primary" @click="$router.push({ name: 'UserInformations', query: { userInformation: scope.row } })">
            {{ scope.row.idcardName }}
          </el-link>
        </template>
      </el-table-column>

      <el-table-column prop="userMobile" label="手机号" width="160" align="center"></el-table-column>
      <el-table-column prop="userEmail" label="邮箱号" width="160" align="center"></el-table-column>
      <el-table-column prop="userType" label="类型" width="140" align="center"></el-table-column>
      <el-table-column label="角色" width="140" align="center">
        <template slot-scope="{ row }">
          <el-tag v-for="role in row.userRoles" :key="role.roleId" :type="role.roleId | filtersRoles" size="mini">
            {{ role.roleName }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="corporation" label="公司" align="center" :show-overflow-tooltip="true">
        <template slot-scope="{ row }">
          <span>{{ row.corporations && row.corporations[0] && row.corporations[0].cpName }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="createTime" label="创建时间" align="center">
        <template slot-scope="scope">
          {{ $moment(scope.row.createTime).format('YYYY-MM-DD HH:mm') }}
        </template>
      </el-table-column>
      <el-table-column label="状态" width="60" align="center">
        <template>
          <el-tag size="mini" type="success">启用</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="226">
        <template slot-scope="scope">
          <el-button type="text" icon="el-icon-setting" @click="handleEditUserRole(scope.row.userId)"> 设置权限</el-button>
          <el-button type="text" icon="el-icon-edit-outline" @click="handleEditUserInfo(scope.row)"> 编辑</el-button>
          <el-button type="text" icon="el-icon-delete" style="color: #f40" v-if="false"> 删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <PaginationBar :table-config="tableConfig" @change:pageNumber="handleCurrentChange" />
    <el-dialog :close-on-click-modal="false" title="用户数据权限设置" :visible="isEditorRole" width="1100px" @close="handleCloseDialog">
      <div class="role-list-wrapper">
        <strong class="wrapper-title role-title">角色列表：</strong>
        <div class="wrap-list role-lisr">
          <el-checkbox v-for="role in roles" :label="role.roleName" :value="role.roleId" :key="role.roleId" v-model="role.isExist" @change="handleClickRoleOptio(role)"></el-checkbox>
        </div>
      </div>
      <el-divider></el-divider>
      <div class="enterprise-project-site-list-wrapper">
        <el-card class="list-wrapper enterprise-lis-wrap">
          <strong class="wrapper-title" slot="header">企业列表</strong>
          <div class="wrap-list enterprise-list">
            <SelectRoleMessageComponent type="cpIds" :role="checkedRoleId" :multiple="permitMultipleOption('cpIds')" :options="corporations" :optionAttr="{ labelKey: 'cpName', valueKey: 'cpId' }" v-if="corporations.length" @get:selected="handleChangeChecked" />
          </div>
        </el-card>
        <el-card class="list-wrapper project-list-wrap">
          <strong class="wrapper-title" slot="header">项目列表</strong>
          <div class="wrap-list project-list">
            <SelectRoleMessageComponent type="pids" :role="checkedRoleId" :multiple="permitMultipleOption('pids')" :options="projects" :optionAttr="{ labelKey: 'pName', valueKey: 'pId' }" v-if="corporations.length" @get:selected="handleChangeChecked" />
          </div>
        </el-card>
        <el-card class="list-wrapper site-list-wrap">
          <strong class="wrapper-title" slot="header">站点列表</strong>
          <div class="wrap-list project-list">
            <SelectRoleMessageComponent type="stationIds" :role="checkedRoleId" :multiple="permitMultipleOption('stationIds')" :options="sites" :optionAttr="{ labelKey: 'sName', valueKey: 'stId' }" v-if="corporations.length" @get:selected="handleChangeChecked" />
          </div>
        </el-card>
      </div>
      <el-divider></el-divider>
      <div class="button-bar">
        <el-button size="small" type="primary" @click="submitCheckedOptions">确认</el-button>
        <el-button size="small" @click="handleCloseDialog">取消</el-button>
      </div>
    </el-dialog>
    <el-dialog :close-on-click-modal="false" title="修改用户信息" :visible="isEditUserInfo" @close="resetForm('WorkspaceInformation')">
      <el-form ref="WorkspaceInformation" :rules="rules" :model="workspaceInformation">
        <el-form-item label="用户名" prop="userName">
          <el-input v-model="workspaceInformation.userName"></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="idcardName" :disabled="workspaceInformation.idcardName ? true : false">
          <el-input v-model="workspaceInformation.idcardName"></el-input>
        </el-form-item>
        <el-form-item label="电话号码" prop="phoneNum">
          <el-input v-model="workspaceInformation.phoneNum"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="workspaceInformation.email"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="subnitWorkspaceInfo('WorkspaceInformation')"> 确定</el-button>
          <el-button size="small" @click="resetForm('WorkspaceInformation')">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-card>
</template>

<script>
import PaginationBar from '@/components/Pagination/index.vue';
import SelectRoleMessageComponent from './component/SelectRoleMessageComponent.vue';
import { getProjectsByCpIds, getSiteListBypId, getUserCorporationList } from '@/api/role.js';
import { editUserInfomation, getRoleGroup, getUserList, setupAssignRoles } from '@/api/user';
import statusInclude from '@/utils/statusCode';
import { checkEmail, checkMobile, checkUserName } from '@/utils/rules.js';
import sleep from '@/utils/sleep';

export default {
  name: 'AdministratorGroup',
  components: {
    PaginationBar,
    SelectRoleMessageComponent,
  },
  filters: {
    filtersRoles(role) {
      const rolesMap = {
        R0001: '',
        R0002: 'success',
        R0003: 'info',
        R0004: 'warning',
        R0005: 'danger',
      };
      return rolesMap[role];
    },
  },
  data() {
    const FUNCTIONENUM = {
      cpIds: 'getProjectsByCpIds',
      pids: 'getSiteListBypId',
      stationIds: '',
    };
    const DBENUM = {
      cpIds: 'projects',
      pids: 'sites',
    };
    return {
      uid: '', //用户ID
      checkedRoleId: '',
      direction: 'rtl',

      isEditorRole: false,
      isBan: false,
      isSiteBan: false,
      isEditUserInfo: false,
      drawer: false,
      roles: [], //server roles
      hasRoles: [], //用户已经拥有的角色
      userList: [],
      corporations: [],
      projects: [],
      sites: [],
      userInformation: {
        name: '',
      },
      //待修改的用户信息
      workspaceInformation: {
        userName: '',
        phoneNum: '',
        userId: '',
        email: '',
        idcardName: '',
      },
      renders: {
        currCorporationId: '',
        currProjectId: '',
        currSiteId: '',
      },
      rules: {
        userName: [{ required: true, validator: checkUserName, trigger: 'blur' }],
        phoneNum: [{ required: true, validator: checkMobile, trigger: 'blur' }],
        email: [{ required: true, validator: checkEmail, trigger: 'blur' }],
      },
      tableConfig: {
        page: 1,
        size: 20,
        total: 0,
      },
      FUNCTIONENUM,
      DBENUM,
    };
  },
  computed: {
    permitMultipleOption: (mode) => {
      return (mode) => {
        const roles = {
          cpIds: {
            R0001: false,
            R0002: false,
            R0003: false,
            R0004: false,
            R0005: true,
            R0006: true,
          },
          pids: {
            R0001: false,
            R0002: true,
            R0003: true,
            R0004: true,
            R0005: true,
            R0006: true,
          },
          stationIds: {
            R0001: true,
            R0002: true,
            R0003: true,
            R0004: true,
            R0005: true,
            R0006: true,
          },
        };
        return roles[mode];
      };
    },
  },
  created() {
    this.getUserList({ ...this.tableConfig });

    this.getHeight();
    Array.prototype.getQualified = function (key) {
      const tmp = this.reduce((per, cur) => {
        Boolean(cur.isExist) && per.push(cur[key]);
        return per;
      }, []);
      return tmp;
    };
  },
  methods: {
    //计算页面高度
    getHeight() {
      this.tHeight = document.getElementsByTagName('body')[0].offsetHeight - this.$store.state.settings.offset;
    },

    //用户名查找用户信息

    handleSearch(name) {
      this.getUserList({ ...this.tableConfig, name });
    },

    handleClear() {
      this.getUserList({ ...this.tableConfig });
    },

    //编辑用户权限
    handleEditUserRole(uid) {
      this.uid = uid;
      this.getRoleListByUserId(uid);
      this.getUserCorporationList(uid);

      this.isEditorRole = true;
    },

    //编辑用户基本信息

    handleEditUserInfo(userInfo) {
      this.isEditUserInfo = true;
      const tmp = JSON.parse(JSON.stringify(userInfo));
      this.workspaceInformation.userId = tmp.userId;
      this.workspaceInformation.userName = tmp.userName;
      this.workspaceInformation.phoneNum = tmp.userMobile;
      this.workspaceInformation.email = tmp.userEmail;
      this.workspaceInformation.idcardName = tmp.idcardName;
    },

    //根据用户角色权限设置用户数据权限
    handleClickRoleOptio(role) {
      const tmp = JSON.parse(JSON.stringify(role));
      this.roles.forEach((role) => {
        role.isExist = false;
        if (role.roleId === tmp.roleId) role.isExist = tmp.isExist;
      });
      this.checkedRoleId = tmp.isExist ? tmp.roleId : '';
      this.isBan = false;
      if (this.checkedRoleId === 'R0004') this.isBan = true;
      this.getUserCorporationList();
    },

    handleClose() {
      console.log(1);
    },

    //获取企业名称
    formatCorporationName(corporations) {
      return corporations && corporations[0]?.cpName;
    },

    //翻页
    handleCurrentChange(page) {
      this.tableConfig.page = page;
      this.getUserList({ ...this.tableConfig });
    },

    async handleChangeChecked({ type, arr }) {
      this[this.DBENUM[type]] = arr.length ? await this[this.FUNCTIONENUM[type]](this.uid, arr) : [];
    },

    async handleChangeProjectOptions(project) {
      const tmpProjects = this.screeProjects();
      this.sites = tmpProjects.length ? await this.getSiteListBypId(this.uid, tmpProjects) : [];
      if (this.checkedRoleId === 'R0003') this.sites.forEach((site) => (site.isExist = true));
    },

    //单选企业
    radioCompanies(corporation) {
      const tmp = JSON.parse(JSON.stringify(corporation));
      this.corporations.forEach((corporation) => {
        corporation.isExist = false;
        if (corporation.cpId === tmp.cpId) corporation.isExist = tmp.isExist;
      });
    },
    //单选项目
    radioProjects(project) {
      const tmp = JSON.parse(JSON.stringify(project));
      this.projects.forEach((project) => {
        project.isExist = false;
        if (project.pId === tmp.pId) project.isExist = tmp.isExist;
      });
    },
    //筛选企业信息
    screeCorporations() {
      const response = this.corporations.reduce((cur, per) => {
        Boolean(per.isExist) && cur.push(per.cpId);
        return cur;
      }, []);
      return response;
    },
    screeProjects() {
      return this.projects.reduce((cur, per) => {
        Boolean(per.isExist) && cur.push(per.pId);
        return cur;
      }, []);
    },
    //点击企业查看此企业下项目信息
    handleClickCorporation(cpId) {
      if (cpId === this.renders.currCorporationId) {
        this.renders.currCorporationId = '';
      } else {
        this.renders.currCorporationId = cpId;
        this.getProjectsByCpIds(this.uid, [cpId]);
      }
      this.projects = [];
      this.sites = [];
      this.renders.currProjectId = '';
      this.renders.currSiteId = '';
    },
    handleClickProject(pId) {
      if (pId === this.renders.currProjectId) {
        this.renders.currProjectId = '';
      } else {
        this.renders.currProjectId = pId;
        this.getSiteListBypId(this.uid, [pId]);
      }
      this.sites = [];
      this.renders.currSiteId = '';
    },
    handleClickSite(stId) {
      if (stId === this.renders.currSiteId) {
        this.renders.currSiteId = '';
      } else {
        this.renders.currSiteId = stId;
      }
    },

    async submitCheckedOptions() {
      const params = {
        userId: this.uid,
        roles: this.roles.getQualified('roleId'),
        stationIds: this.sites.getQualified('stId'),
        cpIds: this.corporations.getQualified('cpId'),
        pids: this.projects.getQualified('pId'),
      };
      const { code, reason } = await setupAssignRoles(params);
      if (!statusInclude(code)) return this.$message.error(`设置用户权限失败：${reason}`);
      this.$message({ type: 'success', message: '权限设置成功' });
      this.handleCloseDialog();
    },

    //关闭
    handleCloseDialog() {
      this.checkedRoleId = '';
      this.sites = [];
      this.projects = [];
      this.corporations = [];
      this.isEditorRole = false;
    },

    //提交修改的用户信息
    subnitWorkspaceInfo(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) this.editAccountInformation(this.workspaceInformation);
      });
    },

    //重置用户信息表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.isEditUserInfo = false;
    },

    //表格高度请求所需要请求的数据size
    async getSourceSize(time) {
      await sleep(time);
      return Math.ceil(parseInt(document.getElementsByClassName('el-table__body-wrapper')[0].style.height) / 51);
    },

    //获取用户列表
    async getUserList({ page, size, name }) {
      const { code, data, reason } = await getUserList(page, size, '', name);
      if (!statusInclude(code)) return this.$message.error(`请求用户信息失败${reason}`);
      const { records, total } = data;
      console.log(records);
      this.tableConfig.total = total;
      this.userList = records;
    },

    //用户编号获取用户角色列表
    async getRoleListByUserId(uid) {
      this.roles = [];
      const { code, data, reason } = await getRoleGroup(uid);
      if (!statusInclude(code)) {
        this.$message.error(`获取角色列表失败:${reason}`);
        throw new Error(reason); //获取角色数据失败之后阻塞后续操作
      }
      //获取已经拥有的角色列表
      this.hasRoles = data.reduce((pre, role) => {
        role.isExist && pre.push(role.roleId);
        return pre;
      }, []);
      this.checkedRoleId = this.hasRoles[0];
      this.roles = data.map((role) => {
        role.isExist = Boolean(role.isExist);
        return role;
      });
    },
    //用户企业列表 初始化企业 项目 站点信息
    async getUserCorporationList(uid) {
      const { code, data, reason } = await getUserCorporationList(uid);
      if (!statusInclude(code)) {
        this.$message.error(`获取企业列表失败:${reason}`);
        throw new Error(reason); //获取角色数据失败之后阻塞后续操作
      }
      this.corporations = data.map((corporation) => {
        corporation.isExist = Boolean(corporation.isExist);
        return corporation;
      });
      const tmpCorporations = this.screeCorporations();
      this.projects = tmpCorporations.length ? await this.getProjectsByCpIds(this.uid, tmpCorporations) : [];
      const tmpProjects = this.screeProjects();
      this.sites = tmpProjects.length ? await this.getSiteListBypId(this.uid, tmpProjects) : [];
    },
    //企业下项目列表
    async getProjectsByCpIds(uid, cpIds) {
      const { code, data, reason } = await getProjectsByCpIds({ userId: uid, cpIds });
      if (!statusInclude(code)) {
        this.$message.error(`获取企业下项目信息失败:${reason}`);
        throw new Error(reason);
      }
      this.projects = data.map((project) => {
        project.isExist = Boolean(project.isExist);
        return project;
      });
      return this.projects;
    },
    async getSiteListBypId(uid, pids) {
      const { code, data, reason } = await getSiteListBypId({ userId: uid, pids });
      if (!statusInclude(code)) {
        this.$message.error(`获取项目下站点信息失败:${reason}`);
        throw new Error(reason);
      }
      this.sites = data.map((site) => {
        site.isExist = Boolean(site.isExist);
        return site;
      });
      return this.sites;
    },
    async editAccountInformation(userInfomation) {
      const { code, reason } = await editUserInfomation(userInfomation);
      if (!statusInclude(code)) return this.$message.error(`修改用户信息失败：${reason}`);
      this.$message({ type: 'success', message: '请求成功' });
      this.isEditUserInfo = false;
      this.getUserList({ ...this.tableConfig });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-dialog__wrapper {
  .el-dialog {
    .el-dialog__body {
      .wrapper-title {
        color: var(--el-color-primary);
      }

      .wrap-list {
        margin: 0 10px;
      }

      .role-list-wrapper {
        background-color: var(--main-el-card-background);
        padding: 10px;

        .wrapper-title {
          display: block;
          margin: 10px;
        }
      }

      .el-divider {
        background: var(--main-border-color);
      }

      .enterprise-project-site-list-wrapper {
        display: flex;
        flex-direction: row;

        .list-wrapper {
          flex: 1;

          &.el-card {
            border-color: var(--main-border-color);
            display: flex;
            flex-direction: column;

            & ::v-deep .el-card__body {
              flex: 1;
              padding: 10px;
              background: var(--main-el-card-background);
            }
          }

          .wrap-list {
            display: flex;
            flex-direction: column;
            max-height: 300px;
            overflow-y: auto;

            .el-checkbox-puls-wrap {
              margin: 4px 0;
              padding-left: 10px;
              display: flex;
              flex-direction: row;
              align-items: center;
              border: 1px solid var(--main-border-color);
              cursor: pointer;

              .el-checkbox {
                height: auto;
                margin: 4px 0px;
                padding: 2px 6px;
                border-radius: 8px;
                border: none;
              }

              .checkbox-title {
                flex: 1;
                padding: 8px 0;
                margin-left: 10px;
                font-size: 12px;
                font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;
                color: var(--color-primary);
              }

              &.handle {
                border-color: var(--main-border-color);
                color: var(--color-primary);

                .checkbox-title {
                  color: #{$primary};
                }
              }

              &.warning {
                border-color: #{$warning};
                color: #{$warning};

                .checkbox-title {
                  color: #{$warning};
                }

                .el-checkbox {
                  .el-checkbox__input {
                    background-color: #f40 !important;
                  }
                }
              }
            }
          }

          &.project-list-wrap {
            margin: 0 10px;
          }
        }
      }
    }
  }
}
</style>
